import { graphql, useStaticQuery } from "gatsby";

export const BeksinskiImages = () => {
  const images = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "images/cases/beksinski/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      detail: file(relativePath: { eq: "images/cases/beksinski/detail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      list_bg: file(
        relativePath: { eq: "images/cases/beksinski/list/background.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      list_detail: file(
        relativePath: { eq: "images/cases/beksinski/list/detail.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_1: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_2: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_3: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_4: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beks_5: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_6: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_7: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_7.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_8: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_8.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_9: file(
        relativePath: { eq: "images/cases/beksinski/content/beks_9.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_1f: file(
        relativePath: { eq: "images/cases/beksinski/full/beks_1f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_2f: file(
        relativePath: { eq: "images/cases/beksinski/full/beks_2f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beks_3f: file(
        relativePath: { eq: "images/cases/beksinski/full/beks_3f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return images;
};
