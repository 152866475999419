import { graphql, useStaticQuery } from "gatsby";

export const MercedesImage = () => {
  const images = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "images/cases/mercedes/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      detail: file(relativePath: { eq: "images/cases/mercedes/detail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      list_bg: file(
        relativePath: { eq: "images/cases/mercedes/list/background.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      list_detail: file(
        relativePath: { eq: "images/cases/mercedes/list/detail.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mer_1: file(
        relativePath: { eq: "images/cases/mercedes/content/mer_1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mer_1f: file(
        relativePath: { eq: "images/cases/mercedes/full/mer_1f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mer_2f: file(
        relativePath: { eq: "images/cases/mercedes/full/mer_2f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mer_3f: file(
        relativePath: { eq: "images/cases/mercedes/full/mer_3f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mer_4f: file(
        relativePath: { eq: "images/cases/mercedes/full/mer_4f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return images;
};
