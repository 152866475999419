import React from "react";
import Image from "gatsby-image";
import { useTeamPhotos } from "shared/Hooks/About/Team";
import { BeksinskiImages } from "./BeksinskiImage";
import Opengraph from 'assets/images/cases/beksinski/opengraph.png'
// icons todo
import VueIcon from "assets/images/technologies/vue.svg";

export const BeksinskiData = () => {
  const images = BeksinskiImages();
  const team_images = useTeamPhotos();
  return {
    color: "#0E0E0E",
    title: "Fundacja Beksiński",
    opengraph: Opengraph,
    seo:
      "Wykonaliśmy witrynę internetową, która daje możliwość poznania jego dorobku artystycznego poprzez eventy organizowane przez Fundację.",
    slug: "beksinski",
    hero: {
      title: "Podróż przez świat Mistrza.",
      color: "#fff",
      background: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.hero.childImageSharp.fluid}
        />
      ),
      detail: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.detail.childImageSharp.fluid}
        />
      ),
    },
    list: {
      background: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.list_bg.childImageSharp.fluid}
        />
      ),
      detail: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.list_detail.childImageSharp.fluid}
        />
      ),
    },
    what_we_did: {
      title: "Fundacja Beksiński - projekt inny niż wszystkie",

      technologies: ["Strony WWW", "UX Workshops"],
      text:
        "Zdzisława Beksińskiego nie trzeba nikomu przedstawiać. Dlatego bez wahania podjęliśmy się projektu strony internetowej dla Fundacji Beksiński, której celem jest promowanie twórczości mistrza. Wykonaliśmy witrynę internetową, która daje możliwość poznania jego dorobku artystycznego poprzez eventy organizowane przez Fundację. Wykorzystaliśmy autorskie rozwiązania, które pozwalają na szybkie i intuicyjne przeglądanie strony oraz wdrożyliśmy estetyczny projekt, dzięki któremu wyróżnia się ona atrakcyjnym designem.",
      list: [
        "Opracowaliśmy strategię projektu",
        "Zaprojektowaliśmy i zakodowaliśmy stronę internetową",
        "Zoptymalizowaliśmy animacje graficzne na stronie WWW.",
        "Zastosowaliśmy framework React.js",
      ],
    },
    content_images: {
      first: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_1.childImageSharp.fluid}
        />
      ),
      second: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_2.childImageSharp.fluid}
        />
      ),
      third: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_3.childImageSharp.fluid}
        />
      ),
      fourth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_4.childImageSharp.fluid}
        />
      ),
      fifth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_5.childImageSharp.fluid}
        />
      ),
      sixth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_6.childImageSharp.fluid}
        />
      ),

      seventh: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_7.childImageSharp.fluid}
        />
      ),

      eighth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_8.childImageSharp.fluid}
        />
      ),
      ninth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.beks_9.childImageSharp.fluid}
        />
      ),
    },
    full_images: {
      first: {
        title: "",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.beks_1f.childImageSharp.fluid}
          />
        ),
      },
      second: {
        title: "",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.beks_2f.childImageSharp.fluid}
          />
        ),
      },
      third: {
        title: "",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.beks_3f.childImageSharp.fluid}
          />
        ),
      },
    },
    headers: {
      first: "Galeria sztuki zamknięta w wirtualnym świecie",
      second: "Kalendarium przyciągające uwagę użytkowników",
    },

    text_boxes: {
      first: {
        title: "Kompleksowy projekt strony internetowej",
        text:
          "Profesjonalne i wszechstronne podejście do tematu pozwoliło nam na wdrożenie najnowszych rozwiązań i technologii. Wykonaliśmy modele szkieletowe, projektowanie UI/UX, programowanie frontend i backend. ",
      },
      second: {
        title: "Moduły dostosowane do potrzeb odbiorców",
        text:
          "Strona interentowa Fundacji Beksiński zawiera wiele intuicyjnych modułów, które dają użytkownikowi szereg możliwości. Dzięki serwisowi może on zarezerwować lub kupić bilet na wystawę, zobaczyć aktualne wydarzenia w kalendarzu. Dodatkowym elementem jest oś czasu, na której przedstawiona jest historia życia i pracy mistrza. Użytkownik ma również dostęp do modułu z podcastami.",
      },
      third: {
        title: "Dzieła artysty na wyciągnięcie ręki",
        text:
          "Na stronie internetowej został podpięty istniejący wcześniej sklep online. Daje on możliwość zakupu plakatów, reprodukcji obrazów czy albumów związanych z twórczością Zdzisława Beksińskiego oraz  umożliwia zakup biletu na wirtualny spacer po galerii prac artysty w Muzeum Historycznym w Sanoku. ",
      },
      fourth: {
        title: "Dynamiczne kontrasty ",
        text:
          "Na stronie internetowej wykorzystano kolorystykę nawiązującą do stylu i twórczości artysty. Dominującym kolorem jest czerń i odcienie szarości, które zostały przełamane kontrastowymi barwami. Całość podkreśla dynamikę strony i nadaje jej unikatowy wygląd. ",
      },
      fifth: {
        title: "Nowoczesne technologie",
        text:
          "W tym projekcie wykorzystaliśmy rozwiązanie React.js, które umożliwia tworzenie zoptymalizowanych interfejsów graficznych. Zastosowanie tej techniki pozwala na budowanie stron WWW, które są wydajniejsze i szybsze od stron budowanych w innych frameworkach i bibliotekach.",
      },
      sixth: {
        title: "Animacje - nieodłączny element strony WWW",
        text:
          "Stworzoną przez nas stronę internetową cechują rozbudowane i zoptymalizowane animacje. Ruchome obrazy i grafiki angażują odbiorców i przykuwają uwagę. Dzięki temu, że zostały zoptymalizowane, nie obciążają systemu - działa on szybko i płynnie. ",
      },
    },
    technologies: {
      title: "Przykładowy dłuższy nagłówek dotyczący technologii",
      text: (
        <>
          Współpracę z agencją InteractiveVision wysoko cenimy za zaangażowanie,
          elastyczność oraz kompleksowość usług. Zawsze mogliśmy liczyć na
          profesjonalne doradztwo w zakresie planowanych działań oraz
          kreatywność.
          <br />
          <br />
          Dotychczasowa współpraca z tą firmą pozwala nam na rekomendowanie jej
          usług innym.
        </>
      ),
      icons: [
        { title: "Vue", icon: VueIcon },
        { title: "Vue", icon: VueIcon },
        { title: "Vue", icon: VueIcon },
        { title: "Vue", icon: VueIcon },
        { title: "Vue", icon: VueIcon },
        { title: "Vue", icon: VueIcon },
        { title: "Vue", icon: VueIcon },
      ],
    },
    marketing: {
      counts: [
        {
          count: "20%",
          title: "Zwiększona konwersja",
        },
        {
          count: 2000,
          title: "Polubień w 1 miesiąc",
        },
        {
          count: "53%",
          title: "zwiększona sprzedaż",
        },
      ],
      team: [
        {
          name: "Patrycja Bilska",
          position: "Project Manager",
          image: (
            <Image alt="" fluid={team_images.Patrycja.childImageSharp.fluid} />
          ),
        },
        {
          name: "Rafał Skórka",
          position: "Frontend Developer",
          image: (
            <Image alt="" fluid={team_images.Rafal.childImageSharp.fluid} />
          ),
        },
        {
          name: "Patryk Białas",
          position: "Graphic designer",
          image: (
            <Image alt="" fluid={team_images.Patryk.childImageSharp.fluid} />
          ),
        },
        {
          name: "Rafał Krawiec",
          position: "Backend Developer",
          image: (
            <Image alt="" fluid={team_images.Krawczyk.childImageSharp.fluid} />
          ),
        },
        {
          name: "Jakub Żmuda",
          position: "Marketing Specialist",
          image: (
            <Image alt="" fluid={team_images.Kuba.childImageSharp.fluid} />
          ),
        },
        {
          name: "Jakub Furman",
          position: "Sales Manager",
          image: (
            <Image alt="" fluid={team_images.Furman.childImageSharp.fluid} />
          ),
        },
      ],
    },
  };
};
