import React from "react";
import Image from "gatsby-image";
import { MercedesImage } from "./MercedesImage";

export const MercedesData = () => {
  const images = MercedesImage();
  return {
    color: "#000000",
    title: "Mercedes",
    seo:
      "Opracowaliśmy projekt aplikacji - Mercedes Me - stworzonej dla światowego potentata samochodowego.",
    slug: "mercedes",
    hero: {
      title: "Ekskluzywna aplikacja dla wymagających.",
      color: "#fff",
      background: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.hero.childImageSharp.fluid}
        />
      ),
      detail: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.detail.childImageSharp.fluid}
        />
      ),
    },
    list: {
      background: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.list_bg.childImageSharp.fluid}
        />
      ),
      detail: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.list_detail.childImageSharp.fluid}
        />
      ),
    },
    what_we_did: {
      title: "Nasza propozycja Aplikacji dla Mercedesa",
      technologies: ["UX Workshops", "Projekt aplikacji"],
      text:
        "Opracowaliśmy projekt aplikacji - Mercedes Me - stworzonej dla światowego potentata samochodowego. Przygotowaliśmy również propozycję redesignu. Wykonany przez nas koncept aplikacji jest przejrzysty i intuicyjny. Mercedes Me to dowód na to, że nie boimy się podejmować wielkich wyzwań oraz okazja, by pochwalić się, jak radzimy sobie z naprawdę wymagającymi i ekskluzywnymi klientami. ",
      list: [
        "Zaprojektowaliśmy nowoczesną aplikację mobilną",
        "Przygotowaliśmy propozycję re-designu",
      ],
    },
    content_images: {
      first: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.mer_1.childImageSharp.fluid}
        />
      ),
    },
    full_images: {
      first: {
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.mer_1f.childImageSharp.fluid}
          />
        ),
      },
      second: {
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.mer_2f.childImageSharp.fluid}
          />
        ),
      },
      third: {
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.mer_3f.childImageSharp.fluid}
          />
        ),
      },
      fourth: {
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.mer_4f.childImageSharp.fluid}
          />
        ),
      },
    },
    text_boxes: {
      first: {
        title: "Mercedes Me - nowoczesny design",
        text:
          "Projekt utrzymany jest w eleganckiej czarnej stylistyce, co sprawia, że jest niezwykle wysublimowany i ponadczasowy. Nasza propozycja wyróżnia się nowoczesną szatą graficzną i minimalizmem. To sprawia, że widok aplikacji jest znacznie prostszy w odbiorze.",
      },
      second: {
        title: "Kompleksowa obsługa samochodu",
        text:
          "Zaprojektowaliśmy prosty interfejs, który umożliwia łatwą nawigację po aplikacji oraz ułatwia jej obsługę. Jak to wygląda w praktyce? Nowoczesny i intuicyjny panel pozwala na bieżąco śledzić, co dzieje się z pojazdem. Nawet wówczas, gdy znajdujesz się poza swoim autem, możesz nad nim czuwać dzięki Mercedes Me.",
      },
      third: {
        title: "Aplikacja, która dba o Twój samochód",
        text:
          "Mercedes Me umożliwia sprawdzenie poziomu benzyny, przypomina o konieczności tankowania, pokazuje, czy pojazd aby na pewno został zamknięty. Oprócz tego daje możliwość kontroli innych płynów i stanu technicznego samochodu. Innowacyjną funkcją aplikacji jest możliwość monitorowania tego, gdzie znajduje się auto, co jest również zabezpieczeniem w przypadku kradzieży. ",
      },
    },
  };
};
