export const ARTPROGRES = "ARTPROGRES";
export const BEEFURY = "BEEFURY";
export const BEKSINSKI = "BEKSINSKI";
export const HEATING = "HEATING";
export const JANCZAKI = "JANCZAKI";
export const KLIMALAB = "KLIMALAB";
export const MATTHIAS = "MATTHIAS";
export const MERCEDES = "MERCEDES";
export const NYKART = "NYKART";
export const PIZZA = "PIZZA";
export const SOCIALMONSTER = "SOCIALMONSTER";
export const SOCIALMONSTERNEW = "SOCIALMONSTERNEW";
export const STREETWEAR = "STREETWEAR";
export const OHTIME = "OHTIME"
export const FIZOSTEO = "FIZOSTEO";
export const PERFUMS = "PERFUMS";
export const FARMA = "FARMA";
export const FUNDACJA = "FUNDACJA";
export const PogorzanskiTygielSmakow = "PogorzanskiTygielSmakow";
export const Bloodlab = "Bloodlab";

export const ROWEROWAPRZYGODA = "ROWEROWAPRZYGODA";
export const PPWB = "PPWB";

export const BESTSHOES = "BESTSHOES";
export const BRUCZEK = "BRUCZEK";
export const GRUPASOLO = "GRUPASOLO";
export const BISPOLWWW = "BISPOLWWW";
export const EXFIT = "EXFIT";
export default {
  ARTPROGRES,
  BEEFURY,
  BEKSINSKI,
  HEATING,
  JANCZAKI,
  KLIMALAB,
  MATTHIAS,
  MERCEDES,
  NYKART,
  PIZZA,
  SOCIALMONSTER,
  SOCIALMONSTERNEW,
  STREETWEAR,
  OHTIME,
  FIZOSTEO,
  PERFUMS,
  FARMA,
  FUNDACJA,
  PogorzanskiTygielSmakow,
  Bloodlab,
  ROWEROWAPRZYGODA,
  PPWB,
  BESTSHOES,
  BRUCZEK,
  GRUPASOLO,
  BISPOLWWW,
  EXFIT
};
