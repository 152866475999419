import Video1 from "assets/videos/pizza/v_1.mp4";
import Video2 from "assets/videos/pizza/v_2.mp4";
import Video3 from "assets/videos/pizza/v_3.mp4";
import Video4 from "assets/videos/pizza/v_4.mp4";
import Video5 from "assets/videos/pizza/v_5.mp4";
import Image from "gatsby-image";
import React from "react";
import {PizzaImage} from "./PizzaImage";

export const PizzaData = () => {
  const images = PizzaImage();
  return {
    color:         "#CC3521",
    title:         "Pizza Box",
    seo:
                   "Opracowaliśmy projekt aplikacji, który umożliwia stworzenie idealnej pizzy oraz dostawę w szybki i prosty sposób.",
    slug:          "pizza-box",
    hero:          {
      title:      "Aplikacja wyśmienita jak pizza.",
      color:      "#ffffff",
      background: (
                    <Image
                      alt=""
                      objectPosition={"center"}
                      objectFit={"cover"}
                      fluid={images.hero.childImageSharp.fluid}
                    />
                  ),
      detail:     (
                    <Image
                      alt=""
                      objectPosition={"center"}
                      objectFit={"contain"}
                      fluid={images.detail.childImageSharp.fluid}
                    />
                  ),
    },
    list:          {
      detail:     (
                    <Image
                      alt=""
                      objectPosition={"center"}
                      objectFit={"contain"}
                      fluid={images.list_detail.childImageSharp.fluid}
                    />
                  ),
      background: (
                    <Image
                      alt=""
                      objectPosition={"center"}
                      objectFit={"contain"}
                      fluid={images.list_bg.childImageSharp.fluid}
                    />
                  ),
    },
    what_we_did:   {
      title:        (
                      <>
                        PizzaBox.
                        <br/> Pomysł na dostawę
                      </>
                    ),
      technologies: [
        "Aplikacja mobilna",
        "UX Workshops",
        "Identyfikacja wizualna",
        "CMS",
      ],
      text:
                    "Kochamy pizzę, a pizza kocha nas. Owocem tej miłości i jednocześnie hołdem dla pizzy jest nasz pomysł na wygląd i funkcjonalność aplikacji dla znanej i uwielbianej sieci Pizza Box. Opracowaliśmy projekt aplikacji, który umożliwia stworzenie idealnej pizzy oraz dostawę w szybki i prosty sposób. W naszej kreacji dominują zdjęcia wspaniale wyglądającej gotowej pizzy, która ma wzbudzać w użytkownikach chęć jak najszybszego złożenia zamówienia.",
      list:         [
        "Zaprojektowaliśmy nowoczesną aplikację mobilną",
        "Opracowaliśmy identyfikację wizualną marki",
        "Wykorzystaliśmy autorski system CMS do zarządzania produktami i treściami",
      ],
    },
    full_images:   {
      first:  {
        image: (
                 <Image
                   alt=""
                   objectPosition={"center"}
                   objectFit={"contain"}
                   fluid={images.piz_1f.childImageSharp.fluid}
                 />
               ),
      },
      second: {
        image: (
                 <Image
                   alt=""
                   objectPosition={"center"}
                   objectFit={"contain"}
                   fluid={images.piz_2f.childImageSharp.fluid}
                 />
               ),
      },
      third:  {
        image: (
                 <Image
                   alt=""
                   objectPosition={"center"}
                   objectFit={"contain"}
                   fluid={images.piz_3f.childImageSharp.fluid}
                 />
               ),
      },
      fourth: {
        image: (
                 <Image
                   alt=""
                   objectPosition={"center"}
                   objectFit={"contain"}
                   fluid={images.piz_4f.childImageSharp.fluid}
                 />
               ),
      },
    },
    video_box:     {
      first:  {
        title: "Nasz świat kręci się wokół pizzy",
        text:
               "Największą zaletą aplikacji jest wyjątkowy kreator umożliwiający dowolne łączenie składników. Dzięki temu masz pewność, że zawsze zjesz dokładnie to, na co masz ochotę. Aplikacja daje podgląd na wygląd ciasta i skomponowanych składników, co pozwala na decydowanie nie tylko o smaku, ale również o wyglądzie pizzy.",
        video: Video1,
      },
      second: {
        title: "Stwórz własną pizzę!",
        text:
               "Nowoczesna aplikacja dla sieci pizzerii Pizza Box pozwala na dowolne łączenie i mieszanie składników przy pomocy specjalnego kreatora. Użytkownik może wybrać rozmiar, później aplikacja podpowiada możliwe do wyboru sosy, a ostatnim krokiem jest dobór dodatków.",
        video: Video2,
      },
      third:  {
        title: "Zapłać za pizzę bez gotówki!",
        text:
               "Aplikacja daje również możliwość wyboru sposobu zapłaty za zamówienie. Wystarczy wybrać formę płatności i potwierdzić dokonanie transakcji za pomocą funkcji Touch ID. To prosty, szybki i całkowicie bezpieczny sposób na to, by w każdej chwili kupić ulubioną pizzę.",
        video: Video3,
      },
    },
    video_gallery: {
      first: [Video4, Video5],
    },
    text_boxes:    {
      first: {
        title: "Aplikacja pełna aromatów",
        text:
               "Każdy miłośnik pizzy wie, że jest to wyjątkowe danie, które potrzebuje wyjątkowej oprawy.  Ma być prosto, estetycznie i ze smakiem. W naszej kreacji wykorzystaliśmy skojarzenia takie jak drewniany stół, na którym serwowana jest wyśmienita pizza, wielokolorowe zioła i przyprawy.             ",
      },
    },
  };
};
